<template>
  <div>
    <div class="flex items-center justify-between mb-2">
      <h2>{{$t('Stock Recalls')}}</h2>
      <vs-alert class="w-1/2" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
        {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
      </vs-alert>
      <vs-button v-else @click="openStockRecallModal()" color="primary" icon-pack="feather" icon="icon-plus" size="large"></vs-button>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadStockRecalls"></head-component>
    <shipblu-table
     :sst="true"
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="stockRequests"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Request ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Number of SKUs')}}</shipblu-th>
        <shipblu-th>{{$t('Total Quantity')}}</shipblu-th>
        <shipblu-th>{{$t('Fulfillment Center')}}</shipblu-th>
        <shipblu-th>{{$t('Number Of Vehicles')}}</shipblu-th>
        <shipblu-th>{{$t('Received On')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-stock-recall-view`, params: { type: 'stock-requests', stockID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].requestID">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ new Date(data[indextr].created).toLocaleDateString('fr-CA')}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfSKU">
            {{ data[indextr].number_of_skus }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].quantity">
            {{ data[indextr].total_quantity }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].fulfillmentCenter">
            {{ data[indextr].fulfillment_center.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].vehicles">
            {{ data[indextr].num_vehicles }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].receivedOn">
            {{ data[indextr].received_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
          <shipblu-td v-if="(!$route.params.merchantID && !$route.params.warehouseID) || $store.state.AppActiveUser.userRole === 'customer-support'">
            <div @click="removeAttribute($event)">
              <vs-dropdown
                :disabled="merchantStatus === 'on_hold'"
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item v-if="data[indextr].status === 'draft'"
                    @click="readyToSend(data[indextr].id)">
                    <span class="flex items-center">
                      <feather-icon
                        icon="SendIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{ $t('Submit')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <add-stock-recall :stockModal="stockModal" @stockModal="stockModal = $event" @loadStockRecalls="loadStockRecalls" :type="type" :stockRequest="stockRequest"></add-stock-recall>
  </div>
</template>

<script>
import AddStockRecall from '../components/AddStockRecall.vue'
import common  from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../../layouts/components/ShipBluTd.vue'
import HeadComponent from '../../components/Header.vue'
import i18nData from '../../../../i18n/i18nData'
import ShipbluPagination from '../../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'draft',
          value: 'draft'
        },
        {
          name: 'submitted',
          value: 'submitted'
        },
        {
          name: 'ready',
          value: 'ready'
        },
        {
          name: 'outbounde',
          value: 'outbounde'
        },
        {
          name: 'recalled',
          value: 'recalled'
        }
      ],
      stockModal: false,
      type: '',
      stockRequests: [],
      stockRequest: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      filters: [],
      cancelledData: {},
      tableLoader: false,
      merchantStatus: ''
    }
  },
  components: {
    AddStockRecall,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    HeadComponent,
    ShipbluPagination
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRecalls()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadStockRecalls()
      }
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStockRecalls()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRecalls()
    },
    getStyle (data) {
      if (data.status === 'ready' || data.status === 'received' || data.status === 'inbounded') {
        return common.getOrderStatusColor('receiving_in_progress')
      } else {
        return common.getOrderStatusColor(data.status)
      }
    },
    getOrderStatusColor (stock) {
      return common.getOrderStatusColor(this.getStatusLabel(stock))
    },
    getStatusLabel (stock) {
      return common.getStatusLabel(stock, this.$store.state.AppActiveUser.userRole)
    },
    openStockRecallModal (type) {
      this.type = type
      if (type === 'New Stock Request') {
        this.stockRequest = Object.assign({}, {})
      } 
      this.stockModal = true
    },
    loadStockRecalls () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&status=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-recalls/${query}`, 'get', null, true,
        (response) => {
          this.stockRequests = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStockRequestsSearch)
    },
    loadStockRequestsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStockRecalls()
    },
    updateStockRequestStatus (data) {
      this.cancelledData = data
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure you want to cancel this stock recall?'],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: this.confirmUpdate
      })
    },
    confirmUpdate () {
      let trackings = []
      trackings = {
        order: this.cancelledData.id,
        status: 'cancelled'
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-recalls/${this.cancelledData.id}/tracking/`, 'post', trackings, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock recall'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadStockRecalls()
        }
      )
    },
    readyToSend (id) {
      const tracking = {
        stock_recall: id,
        status: 'submitted'
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-recalls/${id}/tracking/`, 'post', tracking, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock recall'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadStockRecalls()
        }
      )
    }
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantStatus = user.status
    })
    this.loadStockRecalls()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
table.shipblu-table td:first-child{
  border-left: 0px solid !important;
}
</style>